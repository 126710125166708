import { useAsyncData } from '@snapchat/async-data-browser';
import { htmlEscape, htmlUnescape } from '@snapchat/core';
import { type FC, useContext, useState } from 'react';

import { ConsumerContext } from '../../../../components/ConsumerContextProvider';
import { Config } from '../../../../config';
import { logger } from '../../../../helpers/logging';
import { customFetch } from '../../../../utils/fetch/customFetch';
import { filterFieldMap } from './constants';
import { JobPostings } from './JobPostings';
import type { ElasticJobData, JobQuerySearchHit, ModifiedJobData } from './types';

/**
 * Groups jobs payload into buckets by role since we use seperate tables per job role we also
 * transform the data to be more easily rendered
 *
 * @param data Unbucketed jobs
 * @returns Record<string, ModifiedJobData[]>
 */
const bucketJobs = (data: JobQuerySearchHit[]): Record<string, ModifiedJobData[]> => {
  const bucketedData = data.reduce(
    (acc: Record<string, ModifiedJobData[]>, currentSearchHit: JobQuerySearchHit) => {
      const currentJob = currentSearchHit._source;

      if (!acc[currentJob.role]) {
        acc[currentJob.role] = [];
      }

      acc[currentJob.role]?.push({
        //TODO: modify role field to better match data
        role: currentJob.departments ?? '',
        employment_type: currentJob.employment_type,
        primary_location: currentJob.primary_location,
        title: { text: currentJob.title, url: currentJob.absolute_url },
        locations: currentJob.offices
          ? currentJob.offices.map(office => office.name).join('; ')
          : currentJob.primary_location,
        // Include search results (if any) for title and job description for highlighting
        descriptionSearchResult: currentSearchHit.highlight?.jobDescription?.[0],
        titleSearchResult: currentSearchHit.highlight?.title?.[0],
      });

      return acc;
    },
    {}
  );

  return bucketedData;
};

const filterKeys = Object.keys(filterFieldMap);

export const JobPostingsShallow: FC = () => {
  const { getUrlParams } = useContext(ConsumerContext);
  const [query, setQuery] = useState(new URLSearchParams(getUrlParams?.() ?? {}));
  const filters = Object.fromEntries(filterKeys.map(key => [key, query.get(key) ?? '']));

  const searchQuery = query.get('q');

  const { data, isLoading } = useAsyncData({
    // TODO: Update to use some hashing function instead of JSON.stringify
    dataId: `jobs-${query}-${htmlEscape(JSON.stringify(filters))}`,
    dataAsync: async () => {
      const port = process.env.PORT ?? 3000;
      const host = Config.isClient ? '' : `http://localhost:${port}`;
      const params = new URLSearchParams();

      const filterEntries = Object.entries(filters);

      for (const [key, value] of filterEntries) {
        params.set(key, htmlUnescape(htmlEscape(value)));
      }

      if (searchQuery) {
        params.set('q', htmlEscape(searchQuery));
      }

      const url = filterEntries.length
        ? `${host}/api/jobs?${params.toString()}`
        : `${host}/api/jobs`;

      const res = await customFetch(url);
      const data: ElasticJobData = await res.json();

      return data;
    },
    onError: error => logger.logError({ error, component: 'JobsPostings' }),
  });

  return (
    <JobPostings
      isLoading={isLoading}
      filters={filters}
      jobs={bucketJobs(data?.body ?? [])}
      filterValues={data?.aggs ?? {}}
      setQuery={setQuery}
    />
  );
};
